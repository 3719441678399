import { Component, OnInit, Input } from "@angular/core";
import {
  UntypedFormControl,
  AbstractControl,
  ValidationErrors,
  UntypedFormGroup,
} from "@angular/forms";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngxs/store";

import { Client } from "ng-identity-api";

import { DeleteClient } from "../../store/index";

@Component({
  selector: "app-delete-client",
  templateUrl: "./delete-client.component.html",
  styleUrls: ["./delete-client.component.scss"],
})
export class DeleteClientComponent implements OnInit {
  @Input()
  client: Client;

  form: UntypedFormGroup;

  constructor(private modal: NgbActiveModal, private store: Store) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      clientName: new UntypedFormControl("", equalTo(this.client.clientName)),
    });
  }

  close() {
    this.modal.close();
  }

  delete() {
    this.store.dispatch(new DeleteClient(this.client.clientId));
    this.modal.close();
  }
}

export function equalTo(value: any) {
  return function (control: AbstractControl): ValidationErrors | null {
    if (control.value !== value) {
      return { equalTo: true };
    }

    return null;
  };
}
