import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, Select } from "@ngxs/store";

import { Client } from "ng-identity-api";

import { ClientState, GetClients } from "../../store/index";

import { DeleteClientComponent } from "../delete-client/delete-client.component";
@Component({
  selector: "app-client-list",
  templateUrl: "./client-list.component.html",
  styleUrls: ["./client-list.component.scss"],
})
export class ClientListComponent implements OnInit {
  @Select(ClientState.clients)
  clients$: Observable<Client[]>;

  constructor(private modalService: NgbModal, private store: Store) {}

  delete(client: Client) {
    const modalRef = this.modalService.open(DeleteClientComponent, {
      centered: true,
      size: "lg",
      scrollable: true,
    });

    modalRef.componentInstance.client = client;
  }

  ngOnInit(): void {
    this.store.dispatch(new GetClients());
  }
}
