import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

import { Store } from "@ngxs/store";

import { DirtyComponent, ReactiveFormComponent } from "ng-mcs-forms";

import { ClientState, CreateClient } from "../../store/index";

@Component({
  selector: "app-create-client",
  templateUrl: "./create-client.component.html",
  styleUrls: ["./create-client.component.scss"]
})
export class CreateClientComponent extends ReactiveFormComponent implements DirtyComponent, OnInit {
  created = false;
  form: UntypedFormGroup;

  constructor(private store: Store) {
    super();
  }

  copied(name: string) {
    this.toastService.success(`${name} copied`);
  }

  create() {
    this.store.dispatch(new CreateClient(this.value)).subscribe((_) => {
      const clientName = this.controls.clientName.value;
      const client = this.store.selectSnapshot(ClientState.client)(clientName);
      this.patchValue(client);
      this.created = true;
    });
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      clientId: new UntypedFormControl(""),
      clientName: new UntypedFormControl("", Validators.required),
      clientSecret: new UntypedFormControl("")
    });

    this.form.get("clientId").disable();
    this.form.get("clientSecret").disable();
  }
}
