import { Component, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { NavType, SetNav } from "ng-mcs-desktop";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private zendeskIdentityUrl: string = "https://motorcity.zendesk.com/hc/en-us/categories/1500001525141-IDENTITY";

  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(
      new SetNav([
        {
          audiences: ["id"],
          icon: "user-lock",
          id: "access-levels",
          link: "/access-levels",
          roles: ["Developer", "Administrator"],
          title: "Access Levels",
          type: NavType.application,
        },
        {
          audiences: ["id"],
          icon: "th-large",
          id: "applications",
          link: "/applications",
          roles: ["Developer"],
          title: "Applications",
          type: NavType.application,
        },
        {
          audiences: ["id"],
          icon: "id-badge",
          id: "clients",
          link: "/clients",
          roles: ["Developer", "Administrator"],
          title: "Clients",
          type: NavType.application,
        },
        {
          audiences: ["id"],
          icon: "layer-group",
          id: "tenants",
          link: "/tenants",
          roles: ["Developer"],
          title: "Tenants",
          type: NavType.application,
        },
        {
          audiences: ["id"],
          icon: "layer-group",
          id: "my-tenant",
          link: "/my-tenant",
          roles: ["Administrator"],
          title: "My Tenant",
          type: NavType.application,
        },
        {
          audiences: ["id"],
          icon: "users",
          id: "users",
          link: "/users",
          roles: ["Administrator", "Developer"],
          title: "Users",
          type: NavType.application,
        },
        {
          audiences: ["id"],
          icon: "circle-info",
          id: "help",
          link: this.zendeskIdentityUrl,
          external: true,
          openNewTab: true,
          title: "Help",
          type: NavType.system,
        },
      ])
    );
  }
}
