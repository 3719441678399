import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./components/header/header.component";
import { TabConfigComponent } from "./components/tab-config/tab-config.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgMcsCommonUIModule } from "ng-mcs-common-ui";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [HeaderComponent, TabConfigComponent],
  imports: [CommonModule, NgbModule, NgMcsCommonUIModule, RouterModule.forChild([])],
  exports: [HeaderComponent, TabConfigComponent],
})
export class SharedModule {}
