import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";

import { IdentityUIStateKeys, IdentityUIStates } from "ng-identity-ui";
import { AppStateKeys, AppStates } from "ng-mcs-app";
import { DesktopStateKeys, DesktopStates } from "ng-mcs-desktop";
import { TableCollectionState } from "ng-mcs-table";

import { environment } from "../environments/environment";

import { ClientStateKeys, ClientStates } from "./modules/clients/index";

const states = [].concat(
  AppStates,
  ClientStates,
  DesktopStates,
  IdentityUIStates
);
const stateKeys = [].concat(
  AppStateKeys,
  ClientStateKeys,
  DesktopStateKeys,
  IdentityUIStateKeys
);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // NgxsLoggerPluginModule.forRoot({
    //   disabled: environment.name === "prod",
    // }),
    NgxsModule.forRoot([...states, TableCollectionState], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: stateKeys,
    }),
  ],
  providers: [],
  bootstrap: [],
})
export class AppStoreModule {}
