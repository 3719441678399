import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard, NgMcsAppModule, RoleGuard } from "ng-mcs-app";

import {
  EmailUnverifiedAlertComponent,
  ErrorLayoutComponent,
  ForbiddenComponent,
  HelpComponent,
  InternalServerErrorComponent,
  NgMcsDesktopModule,
  NotFoundComponent,
  ProfileRouteComponent,
  ProtectedLayoutComponent,
} from "ng-mcs-desktop";

const routes: Routes = [
  {
    path: "user/invite/:token",
    pathMatch: "full",
    redirectTo: "/account/invite/:token?email=:email",
  },
  {
    path: "",
    canActivate: [AuthGuard],
    component: ProtectedLayoutComponent,
    children: [
      {
        path: "access-levels",
        canActivate: [RoleGuard],
        loadChildren: () => import("./modules/access-levels/access-level.module").then((m) => m.AccessLevelModule),
        data: {
          roles: ["Developer", "Administrator"],
        },
      },
      {
        path: "applications",
        canActivate: [RoleGuard],
        loadChildren: () => import("./modules/applications/applications.module").then((m) => m.ApplicationsModule),
        data: {
          roles: ["Developer"],
        },
      },
      {
        path: "clients",
        canActivate: [RoleGuard],
        loadChildren: () => import("./modules/clients/clients.module").then((m) => m.ClientsModule),
        data: {
          roles: ["Developer", "Administrator"],
        },
      },
      {
        path: "my-tenant",
        canActivate: [RoleGuard],
        loadChildren: () =>
          import("./modules/org-structure-list/org-structure-list.module").then((m) => m.OrgStructureListModule),
        data: {
          roles: ["Developer", "Administrator"],
        },
      },
      {
        path: "tenants",
        canActivate: [RoleGuard],
        loadChildren: () => import("./modules/tenants/tenants.module").then((m) => m.TenantsModule),
        data: {
          roles: ["Developer"],
        },
      },
      {
        path: "users",
        canActivate: [RoleGuard],
        loadChildren: () => import("./modules/users/users.module").then((m) => m.UsersModule),
        data: {
          roles: ["Developer", "Administrator"],
        },
      },
      {
        path: "",
        component: EmailUnverifiedAlertComponent,
        outlet: "status",
      },
      {
        path: "help",
        component: HelpComponent,
      },
      {
        path: "profile",
        component: ProfileRouteComponent,
      },
    ],
  },
  {
    path: "account",
    loadChildren: () => import("./modules/account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "errors",
    component: ErrorLayoutComponent,
    children: [
      {
        path: "internal-server-error",
        component: InternalServerErrorComponent,
      },
      {
        path: "not-found",
        component: NotFoundComponent,
      },
      {
        path: "forbidden",
        component: ForbiddenComponent,
      },
    ],
  },
];

@NgModule({
  imports: [NgMcsAppModule, NgMcsDesktopModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
  declarations: [],
})
export class AppRoutingModule {}
