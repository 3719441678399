import { ICreateClient } from "ng-identity-api";

export class CreateClient {
  static readonly type = "[Client] Create Client";

  constructor(public data: ICreateClient) {}
}

export class DeleteClient {
  static readonly type = "[Client] Delete Client";

  constructor(public id: string) {}
}

export class GetClients {
  static readonly type = "[Client] Get Clients";

  constructor() {}
}
