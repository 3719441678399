import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";

import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { OAuthModule } from "angular-oauth2-oidc";
import { NgHttpLoaderModule } from "ng-http-loader";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";

import { NgAdminApiModule } from "ng-admin-api";
import { NgIdentityApiModule } from "ng-identity-api";
import { NgIdentityUIModule } from "ng-identity-ui";
import { NgGaugeApiModule } from "ng-gauge-api";
import { ClientConfigurationImpl, NgMcsAppModule } from "ng-mcs-app";
import { NgMcsAppInsightsModule } from "ng-mcs-appinsights";
import { NgMcsCommonUIModule } from "ng-mcs-common-ui";
import { NgMcsDesktopModule } from "ng-mcs-desktop";
import { NgMcsE2EModule } from "ng-mcs-e2e";

import { FormsModule as MCSForms } from "@motorcitysystems/core";

import { AppIconsModule } from "./app-icons.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppStoreModule } from "./app-store.module";

import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { NgGeolocationApiModule } from "ng-geolocation-api";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppIconsModule,
    AppRoutingModule,
    AppStoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgHttpLoaderModule.forRoot(),
    NgGaugeApiModule.forRoot(environment.gaugeBaseUrl, ClientConfigurationImpl),
    NgGeolocationApiModule.forRoot(environment.identityBaseUrl, ClientConfigurationImpl),
    NgIdentityApiModule.forRoot(environment.identityBaseUrl, ClientConfigurationImpl),
    NgAdminApiModule.forRoot(environment.adminBaseUrl, ClientConfigurationImpl),
    NgIdentityUIModule,
    NgMcsAppInsightsModule.forRoot(environment.applicationInsights),
    NgMcsAppModule.forRoot({
      auth: environment.auth,
      version: environment.production ? environment.version : null
    }),
    NgMcsCommonUIModule.forRoot(),
    NgMcsDesktopModule.forRoot({
      apiUrl: environment.identityBaseUrl,
      appId: "identity",
      env: environment.name,
      name: "IDENTITY",
      tagline: "Identity Management",
      identityAppUrl: environment.identityAppUrl
    }),
    NgMcsE2EModule.forRoot({
      readyDebounceTime: 300
    }),
    NgxMaskDirective,
    OAuthModule.forRoot(),
    ReactiveFormsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      registrationStrategy: "registerImmediately",
      enabled: environment.production
    }),
    MCSForms,
    NgxTrimDirectiveModule
  ],
  bootstrap: [AppComponent],
  providers: [NgbActiveModal, provideNgxMask()]
})
export class AppModule {
}
