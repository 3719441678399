<app-header title="Clients">
  <button class="btn btn-link py-0 px-1" [routerLink]="['/clients/create']" leftContent>
    <fa-icon class="fixed" [icon]="['far', 'plus-circle']" [fixedWidth]="true"></fa-icon>
  </button>
</app-header>
<div class="overflow-auto h-100">
  <mcs-data-table
    overflowXBehavior="auto"
    [stickyFooter]="true"
    [showRecordCount]="true"
    [data]="clients$ | async"
    [count]="(clients$ | async).length"
    [pageSize]="(clients$ | async).length"
  >
    <mcs-data-table-column title="Delete" [sortable]="false" [bodyCell]="bodyCell">
      <ng-template #bodyCell let-row="row">
        <button class="btn btn-link p-0" (click)="delete(row)">
          <fa-icon class="fixed" [icon]="['far', 'trash-alt']" [fixedWidth]="true"></fa-icon>
        </button>
      </ng-template>
    </mcs-data-table-column>
    <mcs-data-table-column name="clientName" title="Client Name" [sortable]="false"> </mcs-data-table-column>
    <mcs-data-table-column name="clientId" title="Client ID" [sortable]="false"></mcs-data-table-column>
  </mcs-data-table>
</div>
