import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faClipboardListCheck,
  faCopy,
  faEllipsisV,
  faExclamation,
  faExclamationTriangle,
  faFilter,
  faIdBadge,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faMinus,
  faPencil,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSearch,
  faThLarge,
  faTimes,
  faTrashAlt,
  faUser,
  faUserLock,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";

import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";

const appIcons: any[] = [
  faChevronUp,
  faChevronDown,
  faAngleRight,
  faAngleDown,
  faCheck,
  faCheckCircle,
  faCircle,
  faClipboardListCheck,
  faCopy,
  faEllipsisV,
  faExclamation,
  faExclamationTriangle,
  faFilter,
  faIdBadge,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faMinus,
  faPencil,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSearch,
  faThLarge,
  faTrashAlt,
  faUser,
  faUserLock,
  faUserPlus,
  faUsers,
  faTimes,
  falTimes,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
  providers: [],
  bootstrap: [],
})
export class AppIconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...appIcons);
  }
}
