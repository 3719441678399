import { Injectable } from "@angular/core";
import { map, tap } from "rxjs/operators";

import { CreateClient as CreateClientCommand } from "ng-identity-api";

import { Action, Selector, State, StateContext, NgxsOnInit, Store } from "@ngxs/store";
import { patch, removeItem } from "@ngxs/store/operators";

import { Client, ClientsClient } from "ng-identity-api";
import { upsertItem } from "ngxs-utils";

import { CreateClient, DeleteClient, GetClients } from "./client.actions";

export interface ClientStateModel {
  clients: Client[];
}

@State<ClientStateModel>({
  name: "app_client",
  defaults: {
    clients: [],
  },
})
@Injectable()
export class ClientState implements NgxsOnInit {
  constructor(private store: Store, private clientsClient: ClientsClient) {}

  @Selector()
  static client(state: ClientStateModel): (string) => Client | null {
    const fn = (clientName: string) => {
      return state.clients?.find((s) => s.clientName === clientName);
    };
    return fn;
  }

  @Selector()
  static clients(state: ClientStateModel): Client[] {
    return state.clients;
  }

  @Action(CreateClient)
  createClient(ctx: StateContext<ClientStateModel>, action: CreateClient) {
    const command = new CreateClientCommand(action.data);

    return this.clientsClient.createClient(null, command).pipe(
      tap((item) =>
        ctx.setState(
          patch({
            clients: upsertItem((w) => w.clientId === item.clientId, item),
          })
        )
      ),
      map((_) => {})
    );
  }

  @Action(DeleteClient)
  deleteClient(ctx: StateContext<ClientStateModel>, action: DeleteClient) {
    return this.clientsClient.deleteClient(action.id).pipe(
      tap((t) =>
        ctx.setState(
          patch({
            clients: removeItem<Client>((w) => w.clientId === action.id),
          })
        )
      ),
      map((_) => {})
    );
  }

  @Action(GetClients)
  getClients(ctx: StateContext<ClientStateModel>, action: GetClients) {
    return this.clientsClient.getClients().pipe(
      tap((t) => {
        t.sort((a, b) => a.clientName?.localeCompare(b.clientName));
        ctx.patchState({
          clients: t,
        });
      }),
      map((_) => {})
    );
  }

  ngxsOnInit(ctx?: StateContext<any>) {}
}
