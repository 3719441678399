<div class="px-1">
  <header class="row border-bottom align-items-center d-flex bg-white">
    <div class="col-4 justify-content-start">
      <ng-content select="[leftContent]"></ng-content>
    </div>
    <div *ngIf="title" class="col-4 text-center p-2">{{ title }}</div>
    <div class="col-4 text-end">
      <ng-content select="[rightContent]"></ng-content>
    </div>
  </header>
</div>
