<form [formGroup]="form" (ngSubmit)="delete()">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Delete Client</h4>
    <button type="button" class="btn-close" aria-describedby="modal-title" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <p>
      <strong
        >Are you sure you want to delete
        <span class="text-primary">"{{ client?.clientName }}"</span>
        client?</strong
      >
    </p>
    <p>
      All information associated to this client will be permanently deleted.
      <span class="text-danger">This operation can not be undone.</span>
    </p>

    <hr />

    <p>
      <strong>Please type the name of the client to confirm.</strong>

      <input
        type="text"
        class="form-control"
        id="clientName"
        name="clientName"
        placeholder="Enter client name"
        formControlName="clientName"
      />
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">Cancel</button>
    <button type="submit" class="btn btn-danger" ngbAutofocus [disabled]="form.invalid">Ok</button>
  </div>
</form>
